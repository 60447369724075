import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import Home from "../pages/Home";
import Dois from "../pages/Dois";
import Tres from "../pages/Tres";

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/tusca" exact component={Dois} />
            <Route path="/tres" exact component={Tres} />
        </Switch>
    );
};

export default Routes;
